<template>
  <v-container
    fill-height
    fluid
    style="padding: 0"
    class="d-flex justify-center login-container"
  >
    <div class="ma-6">
      <div class="d-flex flex-column align-center justify-center">
        <img
          :src="require('../../assets/svg/pagoda_square_prelude_connect.svg')"
          width="240px"
          height="180px"
        />
      </div>
      <div id="login-card" class="mb-6">
        <div class="d-flex flex-row justify-center">
          <a href="https://apps.apple.com/us/app/prelude-connect/id1555724713">
            <div class="appstore-badge">
              <img
                alt="Download on the App Store"
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                style="margin: 6%; width: 87%"
              />
            </div>
          </a>
        </div>
        <div class="d-flex flex-row justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.inceptionllc.connect&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <div class="appstore-badge">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    tab: 0,
    validLogin: false,
    email: '',
    password: '',
    url: '',
    hidepass: true,
    loading: false,
    devmode: process.env.NODE_ENV === 'development',
    notification: {
      width: 0,
      type: false,
      show: false,
      message: null
    },
    contact: 'support@preludeconnect.com',
    dialog: false
  }),
  computed: {},
  watch: {
    alert(value) {
      if (value) {
        setTimeout(() => {
          this.alert = false;
        }, 5000);
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
.login-container {
  background: linear-gradient(
    180deg,
    #224d81 0%,
    #36bebf 146.99%,
    #eff2f1 246.91%
  );
  height: 100%;
}

#login-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
}

#login-card {
  border-radius: 16px;
  background-color: transparent;
}

.label-button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}

.label-button:hover {
  cursor: pointer;
}

.decoration-button {
  border-bottom: 1px solid #d6366e;
}

.logo {
  width: 108em;
  height: 108em;

  &--small {
    width: 90em;
    height: 90em;
  }
}

.appstore-badge {
  width: 200px;
}

.appstore-badge > img {
  max-width: 100%;
  height: auto;
}
</style>
